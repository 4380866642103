.navbar-light .navbar-nav .nav-link {
    color: #008000;
}
.company-name {
  color: #008000; /* Green color */
  margin: 0; /* Remove default margin */
  font-size: 1.5rem; /* Adjust size to h2 variant */
}
/* Hero Section */
.carousel-inner, .carousel-item, .video {
    height: 80vh;
  }
  
  .carousel-caption {
    top: 0;
    bottom: 0;
  }
  
  .video {
    object-fit: cover;
  }

/* Destinations Section */
.destinations-section {
  padding: 4rem 0;
}

.card{
  height: 350px !important;
}

.destination-card {
  position: relative;
  overflow: hidden;
  height: 300px; /* Set a fixed height for the cards */
  transition: transform 0.3s ease;
}

.destination-card img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure images cover the card area while maintaining their aspect ratio */
  transition: transform 0.3s ease;
}

.destination-card .card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  transition: background 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.destination-card .btn {
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  border: none;
}

.destination-card:hover {
  transform: scale(1.05);
}

.destination-card:hover img {
  transform: scale(1.1);
}

.destination-card:hover .card-img-overlay {
  background: rgba(0, 0, 0, 0.7);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.6s ease-in-out;
}

.destination-card .btn:hover {
  background-color: #008000;
  color: white;
  border: none;
}

@media screen and (max-width: 350px) {
  .card{
    height: 200px !important;
  }
  
}