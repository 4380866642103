@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap');


html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Manrope", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E8EDF4FF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #008000;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #60CC14FF;
}