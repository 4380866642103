.sign-in-container {
    background-color: #f8f9fa;
    height: 100%;
    position: relative;
  }
  
  .close-icon {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
  }
  
  @media (min-width: 768px) {
    .close-icon {
      top: 40px;
      left: 100px;
    }
  }
  