.enquiry-form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Use viewport height */
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrolling */
  background: rgba(0, 0, 0, 0.5); /* Move backdrop styling here */
  display: flex;
  align-items: center;
  justify-content: center;
}

.enquiry-form {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #008000;
  width: 90%;
  max-width: 500px;
}

.enquiry-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.enquiry-form .form-group {
  margin-bottom: 15px;
}

.enquiry-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.enquiry-form .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.enquiry-form button {
  margin-right: 10px;
}
